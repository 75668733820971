var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FilterSelection",
    {
      attrs: {
        disabled: _vm.disabled,
        "get-item-id": _vm.getPlayerId,
        "get-item-string-compare": _vm.getPlayerName
      },
      scopedSlots: _vm._u([
        {
          key: "selection",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "PlayerCell",
                _vm._b({ attrs: { variant: "row" } }, "PlayerCell", item, false)
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.internalValue,
        callback: function($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue"
      }
    },
    [
      _c("AppAutosuggest", {
        attrs: {
          disabled: _vm.disabled,
          "input-props": _vm.inputProps,
          "section-configs": _vm.sectionConfigs,
          suggestions: _vm.sectionedSuggestions,
          value: _vm.focused ? _vm.search : _vm.placeholder
        },
        on: {
          input: _vm.onInput,
          selected: _vm.onSelect,
          focus: _vm.onFocus,
          blur: _vm.onBlur
        },
        scopedSlots: _vm._u([
          {
            key: "before-suggestions",
            fn: function() {
              return [
                _vm.loading
                  ? _c("Loading")
                  : _vm.error
                  ? _c("ErrorAlert", [_vm._v(" " + _vm._s(_vm.error) + " ")])
                  : !_vm.suggestions.length && _vm.search.length < 3
                  ? _c("InfoAlert", [
                      _vm._v(" Enter at least 3 characters to get results ")
                    ])
                  : !_vm.suggestions.length
                  ? _c("InfoAlert", [_vm._v(" No matching players ")])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function(ref) {
              var suggestion = ref.suggestion
              return [
                _c(
                  "DropdownItem",
                  {
                    attrs: {
                      disabled: _vm.disabled,
                      selected: _vm.selectedPlayerIds[suggestion.item.id]
                    }
                  },
                  [
                    _c(
                      "PlayerCell",
                      _vm._b(
                        { attrs: { variant: "row" } },
                        "PlayerCell",
                        suggestion.item,
                        false
                      )
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("FilterOption", {
        attrs: { value: _vm.option },
        on: { change: _vm.onOptionChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }